/* Responsive YouTube Video Container */
.video-container {
  position: relative;
  width: 100%;
  max-width: calc(90% - 15vw + 4em);
  aspect-ratio: 16 / 9;
  margin: calc(1vw + 3em) auto;
}

iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}
