.jakarta-container {
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f1eeee;
  margin-top: 5rem;
}
.jakarta-banner {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.jakarta-image {
  width: 100%;
  align-self: center;
  object-fit: cover;
}
.jakarta-information {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.jakarta-no-hp {
  gap: 0;
  flex: 0 0 auto;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  margin-left: 0px;
  padding-top: var(--dl-space-space-twounits);
  margin-right: 0px;
  padding-left: 150px;
  border-radius: 25px;
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: 150px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: #d9d9d9;
}
.jakarta-text {
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
}
.jakarta-wa {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.jakarta-image1 {
  width: 60px;
  object-fit: cover;
}
.jakarta-text2 {
  font-size: 60px;
}
.jakarta-text3 {
  font-size: 72px;
  font-style: normal;
  font-weight: 900;
}
@media (max-width: 1024px) {
  .jakarta-no-hp {
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
  .jakarta-text {
    font-size: 55px;
  }
  .jakarta-text3 {
    font-size: 60px;
  }
  .jakarta-text2 {
    font-size: 54px;
  }
}
@media (max-width: 768px) {
  .jakarta-container {
    align-items: center;
    justify-content: flex-start;
  }
  .jakarta-no-hp {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .jakarta-text {
    font-size: 40px;
  }
  .jakarta-image1 {
    width: 40px;
  }
  .jakarta-text3 {
    font-size: 40px;
  }
  .jakarta-text2 {
    font-size: 35px;
  }
}
@media (max-width: 425px) {
  .jakarta-container {
    justify-content: flex-start;
  }
  .jakarta-no-hp {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .jakarta-text {
    font-size: 25px;
  }
  .jakarta-image1 {
    width: 25px;
  }
  .jakarta-text3 {
    font-size: 25px;
  }
  .jakarta-text2 {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .jakarta-container {
    align-items: center;
  }
}
