.container {
    padding-bottom: 3vw;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    justify-items: center;
    align-items: center;
}
.container img {
    max-width: 100%;
    max-height: 200px; /* Adjust this value as needed */
    object-fit: cover;
}

@media (max-width: 992px) {
    .container {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 768px) {
    .container {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 576px) {
    .container {
        grid-template-columns: repeat(1, 1fr);
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.modal img {
    max-width: 90%;
    max-height: 90%;
}

.modal-text {
    position: absolute;
    bottom: -0vh;
    color: white;
    text-align: center;
    width: 100%;
}