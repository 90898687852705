.header-header {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: fixed;
  top: 0;
  align-items: center;
  justify-content: space-between;
  background-color: #F1EEEE;
  z-index: 2;
}
.header-logo {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
}
.header-navlink {
  display: contents;
}
.header-image {
  width: 93px;
  height: 100%;
  align-self: flex-start;
  margin-top: 0px;
  object-fit: cover;
  transition: 0.3s;
  margin-left: 112px;
  text-decoration: none;
}
.header-image:hover {
  cursor: pointer;
}
.header-button {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  padding-right: var(--dl-space-space-sixunits);
  justify-content: flex-end;
}
.header-home {
  font-size: 20px;
  align-self: center;
  font-style: normal;
  transition: 0.3s;
  font-family: Lato;
  font-weight: 700;
  text-decoration: none;
}
.header-home:hover {
  cursor: pointer;
}
.header-profile {
  font-size: 20px;
  font-style: normal;
  transition: 0.3s;
  font-family: Lato;
  font-weight: 700;
  text-decoration: none;
}
.header-profile:hover {
  cursor: pointer;
}
.header-visi-misi {
  font-size: 20px;
  font-style: normal;
  transition: 0.3s;
  font-family: Lato;
  font-weight: 700;
  text-decoration: none;
}
.header-visi-misi:hover {
  cursor: pointer;
}
.header-rally-doa {
  font-size: 20px;
  font-style: normal;
  transition: 0.3s;
  font-family: Lato;
  font-weight: 700;
  text-decoration: none;
}
.header-rally-doa:hover {
  cursor: pointer;
}
.header-hub-kami {
  font-size: 20px;
  font-style: normal;
  transition: 0.3s;
  font-family: Lato;
  font-weight: 700;
}
.header-hub-kami:hover {
  cursor: pointer;
}
.header-thq-dropdown {
  cursor: pointer;
  display: none;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.header-icon {
  width: 24px;
  height: 24px;
}
.header-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.header-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.header-dropdown-toggle1:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-danger-700);
}
.header-text {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.header-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-dropdown-toggle2 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.header-dropdown-toggle2:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-danger-700);
}
.header-text1 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.header-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-dropdown-toggle3 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.header-dropdown-toggle3:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-danger-700);
}
.header-text2 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.header-dropdown3 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-dropdown-toggle4 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.header-dropdown-toggle4:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-danger-700);
}
.header-text3 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.header-dropdown4 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-dropdown-toggle5 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.header-dropdown-toggle5:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-danger-700);
}
.header-text4 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}


@media(max-width: 1440px) {
  .header-button {
    padding-right: var(--dl-space-space-fourunits);
  }
  .header-thq-dropdown {
    display: none;
  }
}
@media(max-width: 1024px) {
  .header-logo {
    margin-left: 5%;
  }
  .header-image {
    margin: 0%;
  }
  .header-button {
    gap: 20px;
    width: 70%;
    padding-right: var(--dl-space-space-threeunits);
  }
  .header-home{
    font-size: 1rem;
  }
  .header-profile {
    font-size: 1rem;
  }
  .header-visi-misi {
    font-size: 1rem;
  }
  .header-rally-doa {
    font-size: 1rem;
  }
  .header-hub-kami {
    font-size: 1rem;
  }
  .header-thq-dropdown {
    display: none;
  }
}
@media(max-width: 768px) {
  .header-button {
    width: auto;
    align-self: center;
    align-items: center;
    padding-right: var(--dl-space-space-unit);
    justify-content: flex-end;
  }
  .header-home {
    display: none;
  }
  .header-profile {
    display: none;
  }
  .header-visi-misi {
    display: none;
  }
  .header-rally-doa {
    display: none;
  }
  .header-hub-kami {
    display: none;
  }
  .header-thq-dropdown {
    width: 152px;
    display: inline-block;
    margin-right: var(--dl-space-space-unit);
  }
  .header-dropdown-toggle {
    justify-content: flex-end;
  }
  .header-icon {
    align-self: flex-end;
  }
  .header-dropdown-list {
    left: 0%;
    display: none;
  }
  .header-dropdown-toggle1 {
    align-items: center;
    justify-content: flex-end;
  }
  .header-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    justify-content: flex-end;
  }
  .header-text1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    justify-content: flex-end;
  }
  .header-text2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    justify-content: flex-end;
  }
  .header-text3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    justify-content: flex-end;
  }
  .header-text4 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    justify-content: flex-end;
  }
  .header-root-class-name {
    height: auto;
  }
}
@media(max-width: 425px) {
  .header-header {
    height: auto;
  }
  .header-image {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: center;
  }
  .header-button {
    align-self: center;
  }
  .header-home {
    font-size: 1rem;
  }
  .header-profile {
    font-size: 1rem;
  }
  .header-visi-misi {
    font-size: 1rem;
  }
  .header-rally-doa {
    font-size: 1rem;
  }
  .header-hub-kami {
    font-size: 1rem;
  }
  .header-thq-dropdown {
    width: auto;
  }
  .header-dropdown-toggle {
    width: var(--dl-size-size-large);
  }
  .header-dropdown-list {
    left: 0%;
  }
  .header-root-class-name1 {
    height: auto;
  }
}
@media(max-width: 375px) {
  .header-image {
    width: var(--dl-size-size-small);
  }
  .header-button {
    align-self: center;
    padding-right: 0px;
  }
  .header-thq-dropdown {
    margin-right: 0px;
  }
}
