.home-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f1eeee;
  margin-top: 5rem;
}
.home-profile-saya {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #dfdfdf;
}
.home-text {
  margin-top: 6rem;
  font-size: 4rem;
  align-self: center;
  font-style: normal;
  font-weight: 900;
}
.home-text001 {
  color: #343434;
  font-size: 1.25rem;
  font-style: normal;
  margin-top: var(--dl-space-space-fiveunits);
  text-align: justify;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: var(--dl-space-space-fourunits);
  text-transform: none;
  text-decoration: none;
}
.home-ayat {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: 155px;
  padding-right: 155px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: flex-start;
}
.home-image {
  flex: 0 0 auto;
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-image01 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-isi-ayat {
  gap: var(--dl-space-space-unit);
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text024 {
  color: rgba(0, 0, 0, 0.4);
  font-size: 1.5rem;
  font-style: normal;
  text-align: justify;
  font-family: Lato;
  font-weight: 700;
  line-height: 1.5;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  text-transform: none;
  text-decoration: none;
}
.home-text025 {
  font-size: 1.5rem;
  align-self: flex-start;
  font-style: italic;
  font-weight: 900;
  padding-left: var(--dl-space-space-threeunits);
}
.home-visi-misi-application {
  gap: var(--dl-space-space-fiveunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 150px;
  padding-left: 300px;
  padding-right: 300px;
  flex-direction: column;
  padding-bottom: 150px;
  justify-content: flex-start;
  background-color: #dfdfdf;
}
.home-visi-dan-misi {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text026 {
  font-size: 96px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 900;
}
.home-text027 {
  color: #343434;
  width: auto;
  height: auto;
  font-size: 1.25rem;
  align-self: center;
  font-style: normal;
  margin-top: 0%;
  text-align: justify;
  font-family: Lato;
  font-weight: 700;
  margin-left: 0%;
}
.home-application {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text034 {
  font-size: 96px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 900;
}
.home-text035 {
  color: #343434;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
}
.home-rally-doa-small {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: none;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text039 {
  font-size: 6rem;
  align-self: center;
  margin-top: 112px;
  text-align: center;
  margin-bottom: 55px;
}
.home-row1 {
  gap: 1.25rem;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
}
.home-bali {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.home-image02 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-btn1 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  text-decoration: none;
  background-color: #f7f6f6;
}
.home-btn1:focus {
  transform: scale(0.96);
}
.home-btn1:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text040 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image03 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-tasik {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.home-image04 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-btn101 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  text-decoration: none;
  background-color: #f7f6f6;
}
.home-btn101:focus {
  transform: scale(0.96);
}
.home-btn101:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text043 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image05 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-row2 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
}
.home-bandung {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.home-btn102 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn102:focus {
  transform: scale(0.96);
}
.home-btn102:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text046 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image06 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-image07 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-jakarta {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.home-btn103 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  text-decoration: none;
  background-color: #f7f6f6;
}
.home-btn103:focus {
  transform: scale(0.96);
}
.home-btn103:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text049 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image08 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-image09 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-row3 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
}
.home-manado {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.home-btn104 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn104:focus {
  transform: scale(0.96);
}
.home-btn104:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text052 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image10 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-image11 {
  width: 100%;
  align-self: center;
  object-fit: cover;
}
.home-makasar {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.home-btn105 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn105:focus {
  transform: scale(0.96);
}
.home-btn105:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text055 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image12 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-image13 {
  width: 100%;
  align-self: center;
  object-fit: cover;
}
.home-row4 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
}
.home-medan {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.home-image14 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-btn106 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn106:focus {
  transform: scale(0.96);
}
.home-btn106:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text058 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image15 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-serpong {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.home-image16 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-btn107 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn107:focus {
  transform: scale(0.96);
}
.home-btn107:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text059 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image17 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-samarinda {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.home-btn108 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn108:focus {
  transform: scale(0.96);
}
.home-btn108:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text060 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image18 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-image19 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-balikpapan {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.home-image20 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-btn109 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn109:focus {
  transform: scale(0.96);
}
.home-btn109:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text063 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image21 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-jayapura {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.home-image22 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-btn110 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn110:focus {
  transform: scale(0.96);
}
.home-btn110:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text066 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image23 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-jogjakarta {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.home-btn111 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn111:focus {
  transform: scale(0.96);
}
.home-btn111:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text069 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image24 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-image25 {
  width: 100%;
  align-self: center;
  object-fit: cover;
}
.home-row5 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  align-items: center;
  margin-bottom: 300px;
  justify-content: center;
}
.home-semarang {
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
}
.home-image26 {
  width: 100%;
  align-self: center;
  object-fit: cover;
}
.home-btn112 {
  left: 90px;
  right: right;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn112:focus {
  transform: scale(0.96);
}
.home-btn112:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text070 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image27 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-surabaya {
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
}
.home-image28 {
  width: 100%;
  align-self: center;
  object-fit: cover;
}
.home-btn2 {
  top: top;
  left: 90px;
  right: right;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn2:focus {
  transform: scale(0.96);
}
.home-btn2:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text071 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image29 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-rally-doa-large {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text072 {
  font-size: 6rem;
  align-self: center;
  margin-top: 112px;
  text-align: center;
  margin-bottom: 55px;
}
.home-row11 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
}
.home-bali1 {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.home-image30 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-btn113 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  text-decoration: none;
  background-color: #f7f6f6;
}
.home-btn113:focus {
  transform: scale(0.96);
}
.home-btn113:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text073 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image31 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-tasik1 {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.home-image32 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-btn114 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  text-decoration: none;
  background-color: #f7f6f6;
}
.home-btn114:focus {
  transform: scale(0.96);
}
.home-btn114:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text076 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image33 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-bandung1 {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.home-btn115 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  text-decoration: none;
  background-color: #f7f6f6;
}
.home-btn115:focus {
  transform: scale(0.96);
}
.home-btn115:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text079 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image34 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-image35 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-row21 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
}
.home-jakarta1 {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.home-btn116 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  text-decoration: none;
  background-color: #f7f6f6;
}
.home-btn116:focus {
  transform: scale(0.96);
}
.home-btn116:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text082 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image36 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-image37 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-manado1 {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.home-btn117 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn117:focus {
  transform: scale(0.96);
}
.home-btn117:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text085 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image38 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-image39 {
  width: 100%;
  align-self: center;
  object-fit: cover;
}
.home-makasar1 {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.home-btn118 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn118:focus {
  transform: scale(0.96);
}
.home-btn118:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text088 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image40 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-image41 {
  width: 100%;
  align-self: center;
  object-fit: cover;
}
.home-row31 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
}
.home-medan1 {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.home-image42 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-btn119 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn119:focus {
  transform: scale(0.96);
}
.home-btn119:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text091 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image43 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-serpong1 {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.home-image44 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-btn120 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn120:focus {
  transform: scale(0.96);
}
.home-btn120:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text092 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image45 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-samarinda1 {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.home-btn121 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn121:focus {
  transform: scale(0.96);
}
.home-btn121:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text093 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image46 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-image47 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-row41 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
}
.home-balikpapan1 {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.home-image48 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-btn122 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn122:focus {
  transform: scale(0.96);
}
.home-btn122:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text096 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image49 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-jayapura1 {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.home-image50 {
  width: 100%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-btn123 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn123:focus {
  transform: scale(0.96);
}
.home-btn123:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text099 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image51 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-jogjakarta1 {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.home-btn124 {
  left: 90px;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn124:focus {
  transform: scale(0.96);
}
.home-btn124:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text102 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image52 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-image53 {
  width: 100%;
  align-self: center;
  object-fit: cover;
}
.home-row51 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  align-items: center;
  margin-bottom: 300px;
  justify-content: center;
}
.home-semarang1 {
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
}
.home-image54 {
  width: 100%;
  align-self: center;
  object-fit: cover;
}
.home-btn125 {
  left: 90px;
  right: right;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn125:focus {
  transform: scale(0.96);
}
.home-btn125:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text103 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image55 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
.home-surabaya1 {
  width: 450px;
  height: auto;
  display: flex;
  position: relative;
}
.home-image56 {
  width: 100%;
  align-self: center;
  object-fit: cover;
}
.home-btn21 {
  top: top;
  left: 90px;
  right: right;
  width: 275px;
  bottom: 25px;
  height: 40px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: center;
  padding-top: 4px;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 100px;
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 7px;
  justify-content: center;
  background-color: #f7f6f6;
}
.home-btn21:focus {
  transform: scale(0.96);
}
.home-btn21:hover {
  cursor: pointer;
  transform: scale(1.15);
  background-color: var(--dl-color-gray-900);
}
.home-text104 {
  color: rgb(52, 52, 52);
  font-size: 1.5rem;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: auto;
  margin-right: 6px;
}
.home-image57 {
  width: 25px;
  align-self: center;
  margin-top: 2px;
  object-fit: cover;
}
@media (max-width: 1440px) {
  .home-profile-saya {
    height: auto;
    padding: var(--dl-space-space-sixunits);
  }
  .home-text {
    margin-top: 1rem;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text001 {
    margin: 0px;
    font-size: 1.25rem;
    font-weight: 700;
    text-align: justify;
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-ayat {
    height: auto;
    padding: 150px;
  }
  .home-text024 {
    font-size: 1.2rem;
    padding-left: 0%;
    padding-right: 0%;
  }
  .home-text025 {
    font-size: 1.2rem;
    padding-left: 0%;
  }
  .home-visi-misi-application {
    height: auto;
    padding-top: 100px;
    padding-left: 250px;
    padding-right: 250px;
    padding-bottom: 100px;
  }
  .home-text027 {
    width: auto;
    font-size: 1.25rem;
    align-self: center;
  }
  .home-rally-doa-small {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-text039 {
    font-size: 5rem;
    margin-top: var(--dl-space-space-sixunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-row1 {
    gap: var(--dl-space-space-halfunit);
    height: auto;
    flex-wrap: wrap;
    align-self: center;
    justify-content: center;
  }
  .home-bali {
    width: 325px;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .home-image02 {
    width: 100%;
    height: auto;
  }
  .home-btn1 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
    position: absolute;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
  }
  .home-text040 {
    color: rgb(52, 52, 52);
    font-size: 1.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image03 {
    margin-top: 0px;
  }
  .home-tasik {
    width: 325px;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .home-image04 {
    width: 100%;
    height: auto;
  }
  .home-btn101 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
    position: absolute;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
  }
  .home-text043 {
    color: rgb(52, 52, 52);
    font-size: 1.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image05 {
    margin-top: 0px;
  }
  .home-row2 {
    gap: var(--dl-space-space-halfunit);
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-halfunit);
  }
  .home-bandung {
    width: 325px;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .home-btn102 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
    position: absolute;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
  }
  .home-text046 {
    color: rgb(52, 52, 52);
    font-size: 1.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image06 {
    margin-top: 0px;
  }
  .home-jakarta {
    width: 325px;
    height: auto;
  }
  .home-btn103 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding-bottom: 4px;
  }
  .home-text049 {
    color: rgb(52, 52, 52);
    font-size: 1.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image08 {
    align-self: center;
    margin-top: 0px;
  }
  .home-image09 {
    width: 100%;
    height: auto;
  }
  .home-row3 {
    gap: var(--dl-space-space-halfunit);
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-halfunit);
  }
  .home-manado {
    width: 325px;
    height: auto;
  }
  .home-btn104 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding-bottom: 4px;
  }
  .home-text052 {
    color: rgb(52, 52, 52);
    font-size: 1.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image10 {
    align-self: center;
    margin-top: 0px;
  }
  .home-image11 {
    width: 100%;
  }
  .home-makasar {
    width: 325px;
    height: 100%;
  }
  .home-btn105 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding-bottom: 4px;
  }
  .home-text055 {
    color: rgb(52, 52, 52);
    font-size: 1.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image12 {
    align-self: center;
    margin-top: 0px;
  }
  .home-image13 {
    width: 100%;
  }
  .home-row4 {
    gap: var(--dl-space-space-halfunit);
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-halfunit);
  }
  .home-medan {
    width: 325px;
    height: auto;
  }
  .home-image14 {
    width: 100%;
    height: auto;
  }
  .home-btn106 {
    left: 0px;
    right: 0px;
    width: 275px;
    bottom: 20px;
    height: auto;
    margin: auto;
    padding: 5px;
  }
  .home-text058 {
    color: rgb(52, 52, 52);
    font-size: 1.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image15 {
    align-self: center;
    margin-top: 0px;
  }
  .home-serpong {
    width: 325px;
    height: auto;
  }
  .home-image16 {
    width: 100%;
    height: auto;
  }
  .home-btn107 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
  }
  .home-text059 {
    font-size: 1.5rem;
  }
  .home-image17 {
    align-self: center;
    margin-top: 0px;
  }
  .home-samarinda {
    width: 325px;
    height: auto;
  }
  .home-btn108 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
  }
  .home-text060 {
    font-size: 1.5rem;
  }
  .home-image18 {
    align-self: center;
    margin-top: 0px;
  }
  .home-image19 {
    width: 100%;
    height: auto;
  }
  .home-balikpapan {
    width: 325px;
    height: auto;
  }
  .home-image20 {
    width: 100%;
    height: auto;
  }
  .home-btn109 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
  }
  .home-text063 {
    color: rgb(52, 52, 52);
    font-size: 26px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image21 {
    margin-top: 0px;
  }
  .home-jayapura {
    width: 325px;
    height: auto;
  }
  .home-image22 {
    width: 100%;
    height: auto;
  }
  .home-btn110 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
  }
  .home-text066 {
    color: rgb(52, 52, 52);
    font-size: 1.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image23 {
    margin-top: 0px;
  }
  .home-jogjakarta {
    width: 325px;
    height: auto;
  }
  .home-btn111 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
  }
  .home-text069 {
    font-size: 26px;
  }
  .home-image24 {
    margin-top: 0px;
  }
  .home-image25 {
    width: 100%;
    height: auto;
  }
  .home-row5 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-halfunit);
  }
  .home-semarang {
    width: 325px;
    height: auto;
  }
  .home-image26 {
    width: 100%;
    height: auto;
    align-self: center;
  }
  .home-btn112 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
  }
  .home-text070 {
    color: rgb(52, 52, 52);
    font-size: 1.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image27 {
    margin-top: 0px;
  }
  .home-surabaya {
    width: 325px;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .home-image28 {
    width: 100%;
  }
  .home-btn2 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
  }
  .home-text071 {
    font-size: 1.5rem;
  }
  .home-image29 {
    margin-top: 0px;
  }
  .home-rally-doa-large {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-text072 {
    font-size: 5rem;
    margin-top: var(--dl-space-space-sixunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-row11 {
    gap: var(--dl-space-space-halfunit);
    height: auto;
    flex-wrap: wrap;
    align-self: center;
    justify-content: center;
  }
  .home-bali1 {
    width: 325px;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .home-image30 {
    width: 100%;
    height: auto;
  }
  .home-btn113 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
    position: absolute;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
  }
  .home-text073 {
    color: rgb(52, 52, 52);
    font-size: 1.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image31 {
    margin-top: 0px;
  }
  .home-tasik1 {
    width: 325px;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .home-image32 {
    width: 100%;
    height: auto;
  }
  .home-btn114 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
    position: absolute;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
  }
  .home-text076 {
    color: rgb(52, 52, 52);
    font-size: 1.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image33 {
    margin-top: 0px;
  }
  .home-bandung1 {
    width: 325px;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .home-btn115 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
    position: absolute;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
  }
  .home-text079 {
    color: rgb(52, 52, 52);
    font-size: 1.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image34 {
    margin-top: 0px;
  }
  .home-row21 {
    gap: var(--dl-space-space-halfunit);
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-halfunit);
  }
  .home-jakarta1 {
    width: 325px;
    height: auto;
  }
  .home-btn116 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding-bottom: 4px;
  }
  .home-text082 {
    color: rgb(52, 52, 52);
    font-size: 1.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image36 {
    align-self: center;
    margin-top: 0px;
  }
  .home-image37 {
    width: 100%;
    height: auto;
  }
  .home-manado1 {
    width: 325px;
    height: auto;
  }
  .home-btn117 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding-bottom: 4px;
  }
  .home-text085 {
    color: rgb(52, 52, 52);
    font-size: 1.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image38 {
    align-self: center;
    margin-top: 0px;
  }
  .home-image39 {
    width: 100%;
  }
  .home-makasar1 {
    width: 325px;
    height: 100%;
  }
  .home-btn118 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding-bottom: 4px;
  }
  .home-text088 {
    color: rgb(52, 52, 52);
    font-size: 1.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image40 {
    align-self: center;
    margin-top: 0px;
  }
  .home-image41 {
    width: 100%;
  }
  .home-row31 {
    gap: var(--dl-space-space-halfunit);
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-halfunit);
  }
  .home-medan1 {
    width: 325px;
    height: auto;
  }
  .home-image42 {
    width: 100%;
    height: auto;
  }
  .home-btn119 {
    left: 0px;
    right: 0px;
    width: 275px;
    bottom: 20px;
    height: auto;
    margin: auto;
    padding: 5px;
  }
  .home-text091 {
    color: rgb(52, 52, 52);
    font-size: 1.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image43 {
    align-self: center;
    margin-top: 0px;
  }
  .home-serpong1 {
    width: 325px;
    height: auto;
  }
  .home-image44 {
    width: 100%;
    height: auto;
  }
  .home-btn120 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
  }
  .home-text092 {
    font-size: 1.5rem;
  }
  .home-image45 {
    align-self: center;
    margin-top: 0px;
  }
  .home-samarinda1 {
    width: 325px;
    height: auto;
  }
  .home-btn121 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
  }
  .home-text093 {
    font-size: 1.5rem;
  }
  .home-image46 {
    align-self: center;
    margin-top: 0px;
  }
  .home-image47 {
    width: 100%;
    height: auto;
  }
  .home-row41 {
    gap: var(--dl-space-space-halfunit);
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-halfunit);
  }
  .home-balikpapan1 {
    width: 325px;
    height: auto;
  }
  .home-image48 {
    width: 100%;
    height: auto;
  }
  .home-btn122 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
  }
  .home-text096 {
    color: rgb(52, 52, 52);
    font-size: 26px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image49 {
    margin-top: 0px;
  }
  .home-jayapura1 {
    width: 325px;
    height: auto;
  }
  .home-image50 {
    width: 100%;
    height: auto;
  }
  .home-btn123 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
  }
  .home-text099 {
    color: rgb(52, 52, 52);
    font-size: 1.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image51 {
    margin-top: 0px;
  }
  .home-jogjakarta1 {
    width: 325px;
    height: auto;
  }
  .home-btn124 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
  }
  .home-text102 {
    font-size: 26px;
  }
  .home-image52 {
    margin-top: 0px;
  }
  .home-image53 {
    width: 100%;
    height: auto;
  }
  .home-row51 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-halfunit);
  }
  .home-semarang1 {
    width: 325px;
    height: auto;
  }
  .home-image54 {
    width: 100%;
    height: auto;
    align-self: center;
  }
  .home-btn125 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
  }
  .home-text103 {
    color: rgb(52, 52, 52);
    font-size: 1.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image55 {
    margin-top: 0px;
  }
  .home-surabaya1 {
    width: 325px;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .home-image56 {
    width: 100%;
  }
  .home-btn21 {
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
    padding: 5px;
  }
  .home-text104 {
    font-size: 1.5rem;
  }
  .home-image57 {
    margin-top: 0px;
  }
}
@media (max-width: 1024px) {
  .home-text035 {
    font-size: 1rem;
  }
  .home-profile-saya {
    padding: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-text {
    font-size: 2rem;
    font-style: normal;
    font-weight: 900;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text001 {
    font-size: 1rem;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-ayat {
    padding: var(--dl-space-space-threeunits);
  }
  .home-isi-ayat {
    gap: var(--dl-space-space-twounits);
  }
  .home-text024 {
    font-size: 1rem;
  }
  .home-text025 {
    font-size: 1rem;
    font-style: italic;
    font-weight: 900;
    padding-left: 0%;
  }
  .home-visi-misi-application {
    padding-left: 100px;
    padding-right: 100px;
  }
  .home-text027 {
    width: auto;
    font-size: 1rem;
  }
  .home-text039 {
    font-size: 4rem;
    margin-top: var(--dl-space-space-fourunits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-bali {
    width: 250px;
  }
  .home-btn1 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
  }
  .home-text040 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image03 {
    width: 18px;
  }
  .home-tasik {
    width: 250px;
  }
  .home-btn101 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
  }
  .home-text043 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image05 {
    width: 18px;
  }
  .home-bandung {
    width: 250px;
  }
  .home-btn102 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
  }
  .home-text046 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image06 {
    width: 18px;
  }
  .home-jakarta {
    width: 250px;
  }
  .home-btn103 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
    padding: 4px;
  }
  .home-text049 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image08 {
    width: 18px;
  }
  .home-manado {
    width: 250px;
  }
  .home-btn104 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
    padding: 4px;
  }
  .home-text052 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image10 {
    width: 18px;
  }
  .home-makasar {
    width: 250px;
  }
  .home-btn105 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
    padding: 4px;
  }
  .home-text055 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image12 {
    width: 18px;
  }
  .home-medan {
    width: 250px;
  }
  .home-btn106 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
  }
  .home-text058 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
  }
  .home-image15 {
    width: 18px;
  }
  .home-serpong {
    width: 250px;
  }
  .home-btn107 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
    padding: 4px;
  }
  .home-text059 {
    color: rgb(52, 52, 52);
  }
  .home-image17 {
    width: 18px;
  }
  .home-samarinda {
    width: 250px;
  }
  .home-btn108 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
  }
  .home-text060 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image18 {
    width: 18px;
  }
  .home-balikpapan {
    width: 250px;
  }
  .home-btn109 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
  }
  .home-text063 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image21 {
    width: 18px;
  }
  .home-jayapura {
    width: 250px;
  }
  .home-btn110 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
  }
  .home-text066 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image23 {
    width: 18px;
  }
  .home-jogjakarta {
    width: 250px;
  }
  .home-btn111 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
  }
  .home-text069 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image24 {
    width: 18px;
  }
  .home-row5 {
    margin-bottom: 150px;
  }
  .home-semarang {
    width: 250px;
  }
  .home-btn112 {
    width: var(--dl-size-size-xlarge);
    height: 30px;
  }
  .home-text070 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image27 {
    width: 18px;
  }
  .home-surabaya {
    width: 250px;
  }
  .home-btn2 {
    width: var(--dl-size-size-xlarge);
    height: 30px;
  }
  .home-text071 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image29 {
    width: 18px;
  }
  .home-text072 {
    font-size: 4rem;
    margin-top: var(--dl-space-space-fourunits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-bali1 {
    width: 250px;
  }
  .home-btn113 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
  }
  .home-text073 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image31 {
    width: 18px;
  }
  .home-tasik1 {
    width: 250px;
  }
  .home-btn114 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
  }
  .home-text076 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image33 {
    width: 18px;
  }
  .home-bandung1 {
    width: 250px;
  }
  .home-btn115 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
  }
  .home-text079 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image34 {
    width: 18px;
  }
  .home-jakarta1 {
    width: 250px;
  }
  .home-btn116 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
    padding: 4px;
  }
  .home-text082 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image36 {
    width: 18px;
  }
  .home-manado1 {
    width: 250px;
  }
  .home-btn117 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
    padding: 4px;
  }
  .home-text085 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image38 {
    width: 18px;
  }
  .home-makasar1 {
    width: 250px;
  }
  .home-btn118 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
    padding: 4px;
  }
  .home-text088 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image40 {
    width: 18px;
  }
  .home-medan1 {
    width: 250px;
  }
  .home-btn119 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
  }
  .home-text091 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
  }
  .home-image43 {
    width: 18px;
  }
  .home-serpong1 {
    width: 250px;
  }
  .home-btn120 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
    padding: 4px;
  }
  .home-text092 {
    color: rgb(52, 52, 52);
  }
  .home-image45 {
    width: 18px;
  }
  .home-samarinda1 {
    width: 250px;
  }
  .home-btn121 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
  }
  .home-text093 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image46 {
    width: 18px;
  }
  .home-balikpapan1 {
    width: 250px;
  }
  .home-btn122 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
  }
  .home-text096 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image49 {
    width: 18px;
  }
  .home-jayapura1 {
    width: 250px;
  }
  .home-btn123 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
  }
  .home-text099 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image51 {
    width: 18px;
  }
  .home-jogjakarta1 {
    width: 250px;
  }
  .home-btn124 {
    width: var(--dl-size-size-xlarge);
    bottom: 15px;
    height: 30px;
  }
  .home-text102 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image52 {
    width: 18px;
  }
  .home-row51 {
    margin-bottom: 150px;
  }
  .home-semarang1 {
    width: 250px;
  }
  .home-btn125 {
    width: var(--dl-size-size-xlarge);
    height: 30px;
  }
  .home-text103 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image55 {
    width: 18px;
  }
  .home-surabaya1 {
    width: 250px;
  }
  .home-btn21 {
    width: var(--dl-size-size-xlarge);
    height: 30px;
  }
  .home-text104 {
    color: rgb(52, 52, 52);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image57 {
    width: 18px;
  }
}
@media (max-width: 768px) {
  .home-profile-saya {
    padding: var(--dl-space-space-twounits);
  }
  .home-ayat {
    gap: var(--dl-space-space-oneandhalfunits);
    padding: var(--dl-space-space-unit);
  }
  .home-isi-ayat {
    gap: var(--dl-space-space-unit);
  }
  .home-text024 {
    color: rgba(0, 0, 0, 0.4);
    font-size: 1rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    line-height: 1.5;
    text-transform: none;
    text-decoration: none;
  }
  .home-text025 {
    padding-left: 0%;
  }
  .home-visi-misi-application {
    padding: 50px;
    gap: var(--dl-space-space-twounits);
  }
  .home-text026 {
    font-size: 50px;
  }
  .home-text034 {
    font-size: 50px;
  }
  .home-text039 {
    font-size: 3.5rem;
  }
  .home-bali {
    width: 175px;
  }
  .home-btn1 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .home-text040 {
    font-size: 12px;
    margin-right: 3px;
  }
  .home-image03 {
    width: 14px;
  }
  .home-tasik {
    width: 175px;
  }
  .home-btn101 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text043 {
    font-size: 12px;
    margin-right: 3px;
  }
  .home-image05 {
    width: 14px;
  }
  .home-bandung {
    width: 175px;
  }
  .home-btn102 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text046 {
    font-size: 12px;
    margin-right: 5px;
  }
  .home-image06 {
    width: 14px;
  }
  .home-jakarta {
    width: 175px;
  }
  .home-btn103 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text049 {
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image08 {
    width: 14px;
  }
  .home-manado {
    width: 175px;
  }
  .home-btn104 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text052 {
    color: rgb(52, 52, 52);
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image10 {
    width: 14px;
  }
  .home-makasar {
    width: 175px;
  }
  .home-btn105 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text055 {
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image12 {
    width: 14px;
  }
  .home-medan {
    width: 175px;
  }
  .home-btn106 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text058 {
    color: rgb(52, 52, 52);
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image15 {
    width: 14px;
  }
  .home-serpong {
    width: 175px;
  }
  .home-btn107 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text059 {
    color: rgb(52, 52, 52);
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image17 {
    width: 14px;
  }
  .home-samarinda {
    width: 175px;
  }
  .home-btn108 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text060 {
    color: rgb(52, 52, 52);
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image18 {
    width: 14px;
  }
  .home-balikpapan {
    width: 175px;
  }
  .home-btn109 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text063 {
    color: rgb(52, 52, 52);
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image21 {
    width: 14px;
  }
  .home-jayapura {
    width: 175px;
  }
  .home-btn110 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text066 {
    color: rgb(52, 52, 52);
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image23 {
    width: 14px;
  }
  .home-jogjakarta {
    width: 175px;
  }
  .home-btn111 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text069 {
    color: rgb(52, 52, 52);
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image24 {
    width: 14px;
  }
  .home-row5 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-semarang {
    width: 175px;
  }
  .home-btn112 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text070 {
    color: rgb(52, 52, 52);
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image27 {
    width: 14px;
  }
  .home-surabaya {
    width: 175px;
  }
  .home-btn2 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text071 {
    color: rgb(52, 52, 52);
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image29 {
    width: 14px;
  }
  .home-text072 {
    font-size: 3.5rem;
  }
  .home-bali1 {
    width: 175px;
  }
  .home-btn113 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .home-text073 {
    font-size: 12px;
    margin-right: 3px;
  }
  .home-image31 {
    width: 14px;
  }
  .home-tasik1 {
    width: 175px;
  }
  .home-btn114 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text076 {
    font-size: 12px;
    margin-right: 3px;
  }
  .home-image33 {
    width: 14px;
  }
  .home-bandung1 {
    width: 175px;
  }
  .home-btn115 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text079 {
    font-size: 12px;
    margin-right: 5px;
  }
  .home-image34 {
    width: 14px;
  }
  .home-jakarta1 {
    width: 175px;
  }
  .home-btn116 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text082 {
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image36 {
    width: 14px;
  }
  .home-manado1 {
    width: 175px;
  }
  .home-btn117 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text085 {
    color: rgb(52, 52, 52);
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image38 {
    width: 14px;
  }
  .home-makasar1 {
    width: 175px;
  }
  .home-btn118 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text088 {
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image40 {
    width: 14px;
  }
  .home-medan1 {
    width: 175px;
  }
  .home-btn119 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text091 {
    color: rgb(52, 52, 52);
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image43 {
    width: 14px;
  }
  .home-serpong1 {
    width: 175px;
  }
  .home-btn120 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text092 {
    color: rgb(52, 52, 52);
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image45 {
    width: 14px;
  }
  .home-samarinda1 {
    width: 175px;
  }
  .home-btn121 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text093 {
    color: rgb(52, 52, 52);
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image46 {
    width: 14px;
  }
  .home-balikpapan1 {
    width: 175px;
  }
  .home-btn122 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text096 {
    color: rgb(52, 52, 52);
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image49 {
    width: 14px;
  }
  .home-jayapura1 {
    width: 175px;
  }
  .home-btn123 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text099 {
    color: rgb(52, 52, 52);
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image51 {
    width: 14px;
  }
  .home-jogjakarta1 {
    width: 175px;
  }
  .home-btn124 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text102 {
    color: rgb(52, 52, 52);
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image52 {
    width: 14px;
  }
  .home-row51 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-semarang1 {
    width: 175px;
  }
  .home-btn125 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text103 {
    color: rgb(52, 52, 52);
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image55 {
    width: 14px;
  }
  .home-surabaya1 {
    width: 175px;
  }
  .home-btn21 {
    width: var(--dl-size-size-large);
    bottom: 10px;
    height: 20px;
    padding: 0px;
  }
  .home-text104 {
    color: rgb(52, 52, 52);
    font-size: 12px;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 3px;
  }
  .home-image57 {
    width: 14px;
  }
}
@media (max-width: 425px) {
  .home-container {
    margin-top: 3rem;
  }
  .home-profile-saya {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .home-text {
    font-size: 2rem;
  }
  .home-text001 {
    margin: 0%;
    font-size: 1rem;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-ayat {
    gap: var(--dl-space-space-oneandhalfunits);
    padding: var(--dl-space-space-unit);
    justify-content: center;
  }
  .home-image {
    width: auto;
    height: 100%;
  }
  .home-image01 {
    width: 100px;
    height: auto;
  }
  .home-isi-ayat {
    gap: var(--dl-space-space-unit);
  }
  .home-text024 {
    font-size: 1rem;
  }
  .home-text025 {
    font-size: 1rem;
  }
  .home-visi-misi-application {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text026 {
    font-size: 50px;
  }
  .home-text027 {
    width: auto;
    margin: 0px;
    font-size: 1rem;
  }
  .home-text034 {
    font-size: 50px;
  }
  .home-text035 {
    font-size: 1rem;
  }
  .home-rally-doa-small {
    width: 100%;
    display: flex;
  }
  .home-text039 {
    font-size: 2rem;
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-row1 {
    gap: 5px;
    margin-top: 0px;
  }
  .home-bali {
    width: 150px;
  }
  .home-btn1 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
  }
  .home-text040 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    text-align: left;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image03 {
    width: 12px;
  }
  .home-tasik {
    width: 150px;
  }
  .home-btn101 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
  }
  .home-text043 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    text-align: left;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image05 {
    width: 12px;
  }
  .home-row2 {
    gap: 5px;
    margin-top: 5px;
  }
  .home-bandung {
    width: 150px;
  }
  .home-btn102 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
  }
  .home-text046 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    text-align: left;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image06 {
    width: 12px;
  }
  .home-jakarta {
    width: 150px;
  }
  .home-btn103 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text049 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image08 {
    width: 12px;
  }
  .home-row3 {
    gap: 5px;
    margin-top: 5px;
  }
  .home-manado {
    width: 150px;
  }
  .home-btn104 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text052 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image10 {
    width: 12px;
  }
  .home-makasar {
    width: 150px;
  }
  .home-btn105 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text055 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image12 {
    width: 12px;
  }
  .home-row4 {
    gap: 5px;
    margin-top: 5px;
  }
  .home-medan {
    width: 150px;
  }
  .home-btn106 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text058 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image15 {
    width: 12px;
  }
  .home-serpong {
    width: 150px;
  }
  .home-btn107 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text059 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    align-self: center;
    font-style: normal;
    text-align: center;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
    text-transform: capitalize;
  }
  .home-image17 {
    width: 12px;
  }
  .home-samarinda {
    width: 150px;
  }
  .home-btn108 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text060 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image18 {
    width: 12px;
  }
  .home-balikpapan {
    width: 150px;
  }
  .home-btn109 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text063 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image21 {
    width: 12px;
  }
  .home-jayapura {
    width: 150px;
  }
  .home-btn110 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text066 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image23 {
    width: 12px;
  }
  .home-jogjakarta {
    width: 150px;
  }
  .home-btn111 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    padding-top: 0px;
    justify-content: center;
  }
  .home-text069 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
    margin-bottom: 0px;
  }
  .home-image24 {
    width: 12px;
  }
  .home-row5 {
    gap: 5px;
    margin-top: 5px;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-semarang {
    width: 150px;
  }
  .home-btn112 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text070 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image27 {
    width: 12px;
  }
  .home-surabaya {
    width: 150px;
  }
  .home-btn2 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text071 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image29 {
    width: 12px;
  }
  .home-rally-doa-large {
    width: 100%;
    display: none;
  }
  .home-text072 {
    font-size: 2rem;
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-row11 {
    gap: 5px;
    margin-top: 0px;
  }
  .home-bali1 {
    width: 150px;
  }
  .home-btn113 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
  }
  .home-text073 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    text-align: left;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image31 {
    width: 12px;
  }
  .home-tasik1 {
    width: 150px;
  }
  .home-btn114 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
  }
  .home-text076 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    text-align: left;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image33 {
    width: 12px;
  }
  .home-bandung1 {
    width: 150px;
  }
  .home-btn115 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
  }
  .home-text079 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    text-align: left;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image34 {
    width: 12px;
  }
  .home-row21 {
    gap: 5px;
    margin-top: 5px;
  }
  .home-jakarta1 {
    width: 150px;
  }
  .home-btn116 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text082 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image36 {
    width: 12px;
  }
  .home-manado1 {
    width: 150px;
  }
  .home-btn117 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text085 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
  }
  .home-image38 {
    width: 12px;
  }
  .home-makasar1 {
    width: 150px;
  }
  .home-btn118 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text088 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image40 {
    width: 12px;
  }
  .home-row31 {
    gap: 5px;
    margin-top: 5px;
  }
  .home-medan1 {
    width: 150px;
  }
  .home-btn119 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text091 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image43 {
    width: 12px;
  }
  .home-serpong1 {
    width: 150px;
  }
  .home-btn120 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text092 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    align-self: center;
    font-style: normal;
    text-align: center;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
    text-transform: capitalize;
  }
  .home-image45 {
    width: 12px;
  }
  .home-samarinda1 {
    width: 150px;
  }
  .home-btn121 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text093 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image46 {
    width: 12px;
  }
  .home-row41 {
    gap: 5px;
    margin-top: 5px;
  }
  .home-balikpapan1 {
    width: 150px;
  }
  .home-btn122 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text096 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image49 {
    width: 12px;
  }
  .home-jayapura1 {
    width: 150px;
  }
  .home-btn123 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text099 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image51 {
    width: 12px;
  }
  .home-jogjakarta1 {
    width: 150px;
  }
  .home-btn124 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    padding-top: 0px;
    justify-content: center;
  }
  .home-text102 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
    margin-bottom: 0px;
  }
  .home-image52 {
    width: 12px;
  }
  .home-row51 {
    gap: 5px;
    margin-top: 5px;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-semarang1 {
    width: 150px;
  }
  .home-btn125 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text103 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image55 {
    width: 12px;
  }
  .home-surabaya1 {
    width: 150px;
  }
  .home-btn21 {
    width: 100px;
    bottom: 8px;
    height: 16px;
    padding: 0px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }
  .home-text104 {
    color: rgb(52, 52, 52);
    font-size: 0.5rem;
    font-style: normal;
    font-family: Lato;
    font-weight: 700;
    margin-right: 2px;
  }
  .home-image57 {
    width: 12px;
  }
}
@media (max-width: 375px) {
  .home-profile-saya {
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-text {
    font-size: 2rem;
    margin-bottom: 5px;
  }
  .home-text001 {
    font-size: 1rem;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-ayat {
    padding: var(--dl-space-space-halfunit);
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-image {
    width: auto;
    height: auto;
    align-self: center;
    align-items: center;
    justify-content: flex-start;
  }
  .home-image01 {
    width: auto;
    height: var(--dl-size-size-medium);
    align-self: center;
  }
  .home-isi-ayat {
    gap: 5px;
    width: auto;
    position: relative;
    align-self: center;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .home-text024 {
    font-size: 1rem;
    align-self: flex-start;
  }
  .home-text025 {
    font-size: 1rem;
    align-self: flex-start;
  }
}
