.footer-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-support {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
}
.footer-text {
  align-self: center;
  margin-top: 33px;
  margin-bottom: 45px;
}
.footer-container1 {
  gap: 80px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-twounits);
  justify-content: center;
}
.footer-image {
  width: 150px;
  object-fit: cover;
}
.footer-image1 {
  width: 100px;
  object-fit: cover;
}
.footer-image2 {
  width: 100px;
  object-fit: cover;
}
.footer-image3 {
  width: 100px;
  object-fit: cover;
}
.footer-image4 {
  width: 100px;
  object-fit: cover;
}
.footer-image5 {
  width: 150px;
  object-fit: cover;
}
.footer-image6 {
  width: 150px;
  object-fit: cover;
}
.footer-image7 {
  width: 100px;
  object-fit: cover;
}
.footer-footer {
  gap: 7px;
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #000000;
}
.footer-image8 {
  width: 23px;
  object-fit: cover;
  margin-left: var(--dl-space-space-sixunits);
}
.footer-text1 {
  color: #f7f6f6;
}

@media (max-width: 1440px) {
  .footer-container1 {
    gap: var(--dl-space-space-fourunits);
    flex-wrap: wrap;
  }
  .footer-image5 {
    width: 100px;
  }
  .footer-image6 {
    width: 100px;
  }
  .footer-image8 {
    margin-left: var(--dl-space-space-fiveunits);
  }
}
@media (max-width: 1024px) {
  .footer-container1 {
    gap: var(--dl-space-space-threeunits);
    flex-wrap: wrap;
  }
  .footer-image {
    width: 150px;
  }
  .footer-image1 {
    width: 100px;
  }
  .footer-image2 {
    width: 100px;
  }
  .footer-image3 {
    width: 100px;
  }
  .footer-image4 {
    width: 100px;
  }
  .footer-image5 {
    width: 100px;
  }
  .footer-image6 {
    width: 100px;
  }
  .footer-image7 {
    width: 100px;
  }
  .footer-image8 {
    margin-left: var(--dl-space-space-fourunits);
  }
}
@media (max-width: 768px) {
  .footer-text {
    font-size: 1rem;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .footer-container1 {
    gap: var(--dl-space-space-threeunits);
    flex-wrap: wrap;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-twounits);
  }
  .footer-image {
    width: 80px;
  }
  .footer-image1 {
    width: 80px;
  }
  .footer-image2 {
    width: 80px;
  }
  .footer-image3 {
    width: 100px;
  }
  .footer-image4 {
    width: 100px;
  }
  .footer-image5 {
    width: 100px;
  }
  .footer-image6 {
    width: 100px;
  }
  .footer-image7 {
    width: 80px;
  }
  .footer-footer {
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-image8 {
    width: 20px;
    margin-left: var(--dl-space-space-twounits);
  }
  .footer-text1 {
    font-size: 14px;
  }
  .footer-root-class-name {
    left: 0px;
    bottom: 0px;
    position: static;
  }
}
@media (max-width: 425px) {
  .footer-text {
    margin-top: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .footer-container1 {
    gap: var(--dl-space-space-twounits);
    flex-wrap: wrap;
    margin-top: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .footer-footer {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-image1 {
    width: 60px;
  }
  .footer-image {
    width: 80px;
  }
  .footer-image2 {
    width: 60px;
  }
  .footer-image3 {
    width: 60px;
  }
  .footer-image7 {
    width: 60px;
  }
  .footer-image6 {
    width: 80px;
  }
  .footer-image5 {
    width: 80px;
  }
  .footer-image4 {
    width: 60px;
  }
  .footer-image3 {
    width: 60px;
  }
  .footer-image8 {
    width: 15px;
    margin-left: var(--dl-space-space-threeunits);
  }
  .footer-text1 {
    font-size: 0.8rem;
  }
  .footer-root-class-name {
    left: 0px;
    bottom: 0px;
    position: static;
    align-self: center;
  }
}
